import React from 'react';
import { Route, Switch } from 'react-router';
import PublicDashboardComponent from '../PublicDashboards';

const PublicDashboardRoute = () => {
    return (
        <Switch>
            <Route exact path={'/CertifiedBuildings'} component={PublicDashboardComponent} />
        </Switch>
    );
};

export default PublicDashboardRoute;
